import { defineStore } from "pinia";
import { AuthAPI, MeAPI } from "@/utils/generated/api-public";

export const useAuthStore = defineStore("auth", () => {
  const me = ref(null);
  const organization = computed(() => me.value?.organizations?.[0]);
  const auth = ref(null);

  async function authenticate(payload = undefined) {
    if (payload && payload.access_token) {
      auth.value = payload;
    } else if (!auth.value && localStorage?.getItem("bearer-token")) {
      auth.value = {
        access_token: localStorage?.getItem("bearer-token"),
        expires_in: null,
        expires_date: null,
      };
    }
    // Calculate expires_date if not set
    if (auth.value && !auth.value.expires_date) {
      auth.value.expires_date = new Date().getTime() + (auth.value.expires_in || 14400) * 1000;
    }

    // Check validity of auth token
    if (auth.value && auth.value.expires_date < new Date().getTime() + 60000) {
      logout();
      return null;
    }
    // Don't load me from server if already loaded and auth still valid
    if (auth.value === payload && me.value) {
      return me.value;
    }

    if (payload && payload.access_token) {
      localStorage.setItem("bearer-token", payload.access_token);
    }

    //  Load me from server
    try {
      me.value = await MeAPI.getMe();
    } catch (err) {
      logout();
    }
    return me.value;
  }

  async function authenticate_by_cookie() {
    try {
      me.value = await AuthAPI.getMeByCookie();
    } catch (err) {
      await logout();
    }
    return me.value;
  }
  async function logout() {
    auth.value = null;
    me.value = null;
    localStorage.removeItem("bearer-token");
    await AuthAPI.logout();
  }

  return {
    me,
    organization,
    auth,
    authenticate,
    logout,
    authenticate_by_cookie,
  };
});
